import { Box } from "@mui/material"
import { appThemeWarningLightColor } from "../../app/Shared.Theme"
import { WarningIcon } from "../Shared.Icon"
import { Body2 } from "../typography/Typography"

interface BannerProps {
  message: string
}

export const WarningBanner = ({ message }: BannerProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: appThemeWarningLightColor,
        padding: "15px 30px",
        borderRadius: "8px",
        marginBottom: "20px",
      }}
    >
      <WarningIcon />
      <Body2 sx={{ marginLeft: "10px" }}>{message}</Body2>
    </Box>
  )
}
