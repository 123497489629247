import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { customerGetAreaById } from "../../../../../../domain/portal/manager/area/Area.Repository"
import { customerUpdateParticipantAppEnabledFlag } from "../../../../../../domain/portal/manager/contracts/Contract.Repository"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { mediumRedColor, mediumRedLightColor } from "../../../../../Shared.Theme"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.grey[500]}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}))

const ZevAppActivation = () => {
  const { t } = useTranslation("settings")
  const { areaId } = useParams<{ areaId: string }>()

  const {
    data: areaData,
    isLoading,
    error: areaError,
    isError: isAreaError,
    remove: removeAreaData,
    refetch: refetchAreaData,
  } = useQuery(["customerGetAreaById"], () => customerGetAreaById(areaId))
  const [isAppEnabled, setIsAppEnabled] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    mutate: updateAppEnabledFlag,
    isLoading: isUpdating,
    isError,
    error: updateError,
  } = useMutation(["updateAppEnabledFlag"], () => customerUpdateParticipantAppEnabledFlag(areaId, !isAppEnabled), {
    onSuccess: () => refetchAreaData(),
    onSettled: () => setIsModalOpen(false),
  })
  const errorMsg = (updateError || areaError) as AxiosErrorDataType

  const handleConfirm = () => updateAppEnabledFlag()
  const handleCancel = () => setIsModalOpen(false)

  useEffect(() => {
    setIsAppEnabled(areaData?.participantAppEnabled ?? false)
  }, [areaData])

  useEffect(() => {
    return () => removeAreaData()
  }, [removeAreaData])

  return (
    <>
      <ErrorAlert
        scrollOnDisplay
        visible={isError || isAreaError}
        message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
      />
      <StyledBox data-testid="zevAppActivationBox">
        <Box>
          <Typography variant="h6" gutterBottom data-testid="zevAppActivationTitle">
            {t("zevAppActivation.title")}
          </Typography>
          <Typography variant="caption" data-testid="zevAppActivationSubTitle">
            {t("zevAppActivation.description")}
          </Typography>
        </Box>
        <Switch
          checked={isAppEnabled}
          onChange={() => setIsModalOpen(true)}
          color="primary"
          disabled={isLoading || isUpdating}
          data-testid="zevAppActivationSwitch"
        />
      </StyledBox>
      <Dialog open={isModalOpen} onClose={handleCancel}>
        <DialogTitle variant="h4">
          {t(`zevAppActivation.${isAppEnabled ? "removeAccess" : "giveAccess"}.confirmTitle`)}
        </DialogTitle>
        <DialogContent>
          {t(`zevAppActivation.${isAppEnabled ? "removeAccess" : "giveAccess"}.confirmMessage`)}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirm}
            variant="contained"
            disabled={isUpdating}
            sx={{
              backgroundColor: mediumRedColor,
              "&:hover": { backgroundColor: mediumRedLightColor },
              "&:disabled": { backgroundColor: mediumRedLightColor },
              padding: "12px 18px",
            }}
            data-testid="zevAppActivationConfirmBtn"
          >
            {t(`zevAppActivation.${isAppEnabled ? "removeAccess" : "giveAccess"}`)}
          </Button>
          <Button onClick={handleCancel} variant="outlined" data-testid="zevAppActivationCancelBtn">
            {t("zevAppActivation.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ZevAppActivation
