import { SvgIconProps } from "@mui/material"

import AccountBoxIcon from "@mui/icons-material/AccountBox"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import AccountCircleIconOutlined from "@mui/icons-material/AccountCircleOutlined"
import AddIcon from "@mui/icons-material/Add"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import ApartmentIconField from "@mui/icons-material/Apartment"
import ApartmentIconOutlined from "@mui/icons-material/ApartmentOutlined"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDownOutlined"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUpOutlined"
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import AssignmentIcon from "@mui/icons-material/AssignmentOutlined"
import BlockIcon from "@mui/icons-material/BlockOutlined"
import Brightness1 from "@mui/icons-material/Brightness1"
import Cached from "@mui/icons-material/Cached"
import CancelRoundedIcon from "@mui/icons-material/CancelRounded"
import ExtensionIcon from "@mui/icons-material/CategoryOutlined"
import CheckCircleFilled from "@mui/icons-material/CheckCircle"
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline"
import ClearIcon from "@mui/icons-material/Clear"
import CloseIcon from "@mui/icons-material/Close"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import ComputerIcon from "@mui/icons-material/ComputerOutlined"
import CreditCardIcon from "@mui/icons-material/CreditCardOutlined"
import DeleteIcon from "@mui/icons-material/Delete"
import DoneIcon from "@mui/icons-material/Done"
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb"
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt"
import EditIconMui from "@mui/icons-material/Edit"
import EmailIcon from "@mui/icons-material/EmailOutlined"
import ErrorOutlined from "@mui/icons-material/ErrorOutlined"
import FeedIcon from "@mui/icons-material/FeedOutlined"
import HomeIconFilled from "@mui/icons-material/Home"
import HomeIconMui from "@mui/icons-material/HomeOutlined"
import HomeWorkIcon from "@mui/icons-material/HomeWorkOutlined"
import Info from "@mui/icons-material/Info"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp"
import LaunchIcon from "@mui/icons-material/LaunchOutlined"
import LocalPhoneFilled from "@mui/icons-material/LocalPhone"
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import LowPriorityIcon from "@mui/icons-material/LowPriorityOutlined"
import NavigateNextOutlined from "@mui/icons-material/NavigateNextOutlined"
import PeopleIcon from "@mui/icons-material/People"
import PersonIconFilled from "@mui/icons-material/Person"
import PersonIcon from "@mui/icons-material/PersonOutlined"
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroidOutlined"
import PieChartIcon from "@mui/icons-material/PieChartOutlineOutlined"
import PowerInputIcon from "@mui/icons-material/PowerInputOutlined"
import PowerIcon from "@mui/icons-material/PowerOutlined"
import ReceiptIcon from "@mui/icons-material/ReceiptOutlined"
import RemoveIconMui from "@mui/icons-material/Remove"
import Replay from "@mui/icons-material/Replay"
import RotateLeft from "@mui/icons-material/RotateLeft"
import SaveIconMui from "@mui/icons-material/Save"
import Search from "@mui/icons-material/Search"
import Send from "@mui/icons-material/Send"
import SyncIcon from "@mui/icons-material/Sync"
import TuneIcon from "@mui/icons-material/Tune"
import ViewListIcon from "@mui/icons-material/ViewListOutlined"
import ColdWater from "../../res/cold_water.svg"
import CostShareSliderThumb from "../../res/CostShareSliderThumb.svg"
import FileDownloadIconSvg from "../../res/fileDownload.svg"
import FileDownloadCardIconSvg from "../../res/FileDownloadCard.svg"
import Fire from "../../res/fire.svg"
import AreaIconSvg from "../../res/newDesign/areaIcon.svg"
import BillingIconSvg from "../../res/newDesign/billIcon.svg"
import BuildingIconSvg from "../../res/newDesign/buildingIcon.svg"
import DocumentIconSvg from "../../res/newDesign/documentIcon.svg"
import EnergySupplier from "../../res/newDesign/energySupplier.svg"
import MeterIconSvg from "../../res/newDesign/meterIcon.svg"
import OpenLink from "../../res/newDesign/openLink.svg"
import ProductIconBlack from "../../res/newDesign/productIcon.svg"
import ProfileIconSvg from "../../res/newDesign/profileIcon.svg"
import TaskListIconSvg from "../../res/newDesign/taskIcon.svg"
import UsersNewIconSvg from "../../res/newDesign/usersIcon.svg"
import UtilityUnitIconSvg from "../../res/newDesign/utilityUnitIcon.svg"
import WarningSvg from "../../res/newDesign/warningIcon.svg"
import PasswordVisibility from "../../res/passwordVisibility.svg"
import PasswordVisibilityOff from "../../res/passwordVisibilityOff.svg"
import ReloadData from "../../res/ReloadData.svg"
import SnowFlake from "../../res/snowflake.svg"
import SolarIconSvg from "../../res/solarpanel.svg"
import PowerPoleDaySvg from "../../res/strommastDay.svg"
import PowerPoleNightSvg from "../../res/strommastNight.svg"
import WarmWater from "../../res/warm_water.svg"
import SettingIconSvg from "../../res/Zaehler-06.svg"

export const ProfileIcon = () => <img src={ProfileIconSvg} alt={"ProfileIconSvg"} />
export const UsersNewIcon = () => <img src={UsersNewIconSvg} alt={"UsersNewIconSvg"} />
export const ProductIcon = (props: SvgIconProps) => <ExtensionIcon {...props} />
export const ProductIconNew = () => <img src={ProductIconBlack} alt={"ProductIconSvg"} />
export const ContractsIcon = (props: SvgIconProps) => <AssignmentIcon {...props} />
export const BillingIcon = (props: SvgIconProps) => <ReceiptIcon {...props} />
export const TodoListIcon = (props: SvgIconProps) => <ViewListIcon {...props} />
export const ApartmentIcon = (props: SvgIconProps) => <ApartmentIconField {...props} />
export const ZevsIcon = (props: SvgIconProps) => <ApartmentIconOutlined {...props} />
export const ConsumptionPointsIcon = (props: SvgIconProps) => <LowPriorityIcon {...props} />
export const ConnectionObjectsIcon = (props: SvgIconProps) => <HomeWorkIcon {...props} />
export const MeasurementIcon = (props: SvgIconProps) => <PowerInputIcon {...props} />
export const ContactIcon = (props: SvgIconProps) => <PersonIcon {...props} />
export const ContactIconFilled = (props: SvgIconProps) => <PersonIconFilled {...props} />
export const TelephoneIcon = (props: SvgIconProps) => <LocalPhoneIcon {...props} />
export const TelephoneIconFilled = (props: SvgIconProps) => <LocalPhoneFilled {...props} />
export const MobilePhoneIcon = (props: SvgIconProps) => <PhoneAndroidIcon {...props} />
export const CorrespondenceIcon = (props: SvgIconProps) => <EmailIcon {...props} />
export const AddressIcon = (props: SvgIconProps) => <HomeIconMui {...props} />
export const MainContactIcon = (props: SvgIconProps) => <AccountCircleIcon {...props} />
export const MainContactIconOutlined = (props: SvgIconProps) => <AccountCircleIconOutlined {...props} />
export const PaymentInformationIcon = (props: SvgIconProps) => <CreditCardIcon {...props} />
export const ButtonCtaIcon = (props: SvgIconProps) => <ArrowRightIcon {...props} />
export const OpenIcon = (props: SvgIconProps) => <LaunchIcon {...props} />
export const OpenIconNew = () => <img src={OpenLink} alt={"OpenLink"} />
export const UserProfileIcon = (props: SvgIconProps) => <AccountBoxIcon {...props} />
export const RemoveIcon = (props: SvgIconProps) => <DeleteIcon {...props} />
export const PlusIcon = (props: SvgIconProps) => <AddIcon {...props} />
export const PlusCircleIcon = (props: SvgIconProps) => <AddCircleIcon {...props} />
export const TickIcon = (props: SvgIconProps) => <DoneIcon {...props} />
export const CrossIcon = (props: SvgIconProps) => <ClearIcon {...props} />
export const ActivateIcon = (props: SvgIconProps) => <CheckCircleIcon {...props} />
export const ActivateIconFilled = (props: SvgIconProps) => <CheckCircleFilled {...props} />
export const DeactivateIcon = (props: SvgIconProps) => <DoNotDisturbAltIcon {...props} />
export const NotDisturbIcon = (props: SvgIconProps) => <DoNotDisturbIcon {...props} />
export const EditIcon = (props: SvgIconProps) => <EditIconMui {...props} />
export const SaveIcon = (props: SvgIconProps) => <SaveIconMui {...props} />
export const ResetIcon = (props: SvgIconProps) => <RotateLeft {...props} />
export const MinusIcon = (props: SvgIconProps) => <RemoveIconMui {...props} />
export const CancelIcon = (props: SvgIconProps) => <BlockIcon {...props} />
export const CancelRoundIcon = (props: SvgIconProps) => <CancelRoundedIcon {...props} />
export const ArrowDownIcon = (props: SvgIconProps) => <ArrowDropDownIcon {...props} />
export const ArrowUpIcon = (props: SvgIconProps) => <ArrowDropUpIcon {...props} />
export const DownloadIcon = (props: SvgIconProps) => <CloudDownloadIcon {...props} />
export const BackIcon = (props: SvgIconProps) => <ArrowBackIcon {...props} />
export const HomeIcon = (props: SvgIconProps) => <HomeIconMui {...props} />
export const HomeFilledIcon = (props: SvgIconProps) => <HomeIconFilled {...props} />
export const BreadcrumbIcon = (props: SvgIconProps) => <NavigateNextOutlined {...props} />
export const CreateIcon = (props: SvgIconProps) => <AddCircleIcon {...props} />
export const RetryIcon = (props: SvgIconProps) => <Replay {...props} />
export const AbortIcon = (props: SvgIconProps) => <CloseIcon {...props} />
export const SendIcon = (props: SvgIconProps) => <Send {...props} />
export const RefreshIcon = (props: SvgIconProps) => <Cached {...props} />
export const DashboardIcon = (props: SvgIconProps) => <ComputerIcon {...props} />
export const AnalysisIcon = (props: SvgIconProps) => <PieChartIcon {...props} />
export const ParticipantIcon = (props: SvgIconProps) => <PersonIcon {...props} />
export const ConnectedObjectsIcon = (props: SvgIconProps) => <PowerIcon {...props} />
export const BillIcon = (props: SvgIconProps) => <FeedIcon {...props} />
export const MyZevsIcon = (props: SvgIconProps) => <HomeIconMui {...props} />
export const ArrowForwardIcon = (props: SvgIconProps) => <ArrowForwardIosSharpIcon {...props} />
export const SettingIcon = () => <img src={SettingIconSvg} alt={"SettingIconSvg"} />
export const SolarIconSvgIcon = () => <img src={SolarIconSvg} alt={"SolarIconSvg"} />
export const PowerPoleDaySvgIcon = () => <img src={PowerPoleDaySvg} alt={"PowerPoleDaySvg"} />
export const PowerPoleNightSvgIcon = () => <img src={PowerPoleNightSvg} alt={"PowerPoleNightSvg"} />
export const InfoIcon = (props: SvgIconProps) => <Info {...props} />
export const InfoIconOutlined = (props: SvgIconProps) => <InfoOutlinedIcon {...props} />
export const ErrorOutlinedIcon = (props: SvgIconProps) => <ErrorOutlined {...props} />
export const DocumentIcon = () => <img src={DocumentIconSvg} alt={"ContractsManagementIcon"} />
export const LocationOn = (props: SvgIconProps) => <LocationOnIcon {...props} />
export const UsersIcon = (props: SvgIconProps) => <PeopleIcon {...props} />
export const AreaIcon = () => <img src={AreaIconSvg} alt={"AreaIconSvg"} />
export const BuildingIcon = () => <img src={BuildingIconSvg} alt={"BuildingIconSvg"} />
export const UtilityUnitIcon = () => <img src={UtilityUnitIconSvg} alt={"UtilityUnitIconSvg"} />
export const MeterIcon = () => <img src={MeterIconSvg} alt={"MeterIconSvg"} />
export const BillIconNew = () => <img src={BillingIconSvg} alt={"BillingIconSvg"} />
export const TaskListIcon = () => <img src={TaskListIconSvg} alt={"TaskListIconSvg"} />
export const BrightnessIcon = (props: SvgIconProps) => <Brightness1 {...props} />
export const Settings = (props: SvgIconProps) => <TuneIcon {...props} />
export const Sync = (props: SvgIconProps) => <SyncIcon {...props} />
export const FileDownload = () => <img src={FileDownloadIconSvg} alt={"FileDownloadIconSvg"} />
export const FileDownloadCard = () => <img src={FileDownloadCardIconSvg} alt={"FileDownloadCardIconSvg"} />
export const SearchIcon = (props: SvgIconProps) => <Search {...props} />
export const FireIcon = () => <img src={Fire} alt={"Fire"} />
export const SnowFlakeIcon = () => <img src={SnowFlake} alt={"SnowFlake"} />
export const WarmWaterIcon = () => <img src={WarmWater} alt={"WarmWater"} />
export const ColdWaterIcon = () => <img src={ColdWater} alt={"ColdWater"} />
export const CostShareSliderThumbIcon = () => <img src={CostShareSliderThumb} alt={"CostShareSliderThumb"} />
export const KeyboardArrowUpIcon = (props: SvgIconProps) => <KeyboardArrowUp {...props} />
export const KeyboardArrowDownIcon = (props: SvgIconProps) => <KeyboardArrowDown {...props} />
export const PasswordOn = () => <img src={PasswordVisibility} alt={"PasswordVisibility"} />
export const PasswordOff = () => <img src={PasswordVisibilityOff} alt={"PasswordVisibilityOff"} />
export const ReloadDataIcon = () => <img src={ReloadData} alt={"ReloadData"} />
export const EnergySupplierIcon = () => <img src={EnergySupplier} alt={"Energy supplier"} />
export const WarningIcon = () => <img src={WarningSvg} alt={"Warning Icon"} />
