import { ButtonTypeMap, Button as MuiButton, styled } from "@mui/material"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"
import React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { AdminPortalRouteParams } from "../../app/portal/admin/AdminPortal.Routes"

interface TextButtonProps extends DefaultComponentProps<ButtonTypeMap> {
  id?: string
  label: string
  color?: "primary" | "secondary" | "error"
}

const Button = styled(MuiButton)(({ theme }) => ({
  marginLeft: 0,
  fontWeight: 300,
  textTransform: "none",
  fontFamily: "Montserrat",
  fontSize: theme.spacing(2),
}))

export const TextButton = ({ id, label, color = "primary", ...rest }: TextButtonProps) => (
  <Button id={id} variant="text" color={color} data-testid="textButton" {...rest}>
    {label}
  </Button>
)

interface AppRouteButtonProps extends RouteComponentProps<AdminPortalRouteParams> {
  id?: string
  label: string
  resolvePath: (params: AdminPortalRouteParams) => string
  style?: React.CSSProperties
}

export const AppRouteButton = withRouter((props: AppRouteButtonProps) => {
  const { id, label, match, history, resolvePath, ...rest } = props
  return <TextButton id={id} label={label} {...rest} onClick={() => history.push(resolvePath(match.params))} />
})
