import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { TFunction } from "i18next"
import { DefaultCell } from "../../../../../../uikit/dataGridTable"
import { StatusView } from "../../../../../../uikit/label/StatusView"

export const ZevAppParticipantsListColumns: (t: TFunction) => Array<GridBaseColDef> = (t: TFunction) => [
  {
    field: "utilityUnit",
    headerName: t("customer-participants:tableHeader.utilityUnit"),
    valueGetter: (_, row) => row?.utilityUnitName,
    renderCell: DefaultCell,
    sortable: true,
    flex: 200,
  },
  {
    field: "lastName",
    headerName: t("settings:label.participant"),
    flex: 250,
    valueGetter: (_, row) => `${row?.participantFirstName} ${row?.participantLastName}`,
    renderCell: ({ value, ...rest }) => <DefaultCell value={value} {...rest} />,
    sortable: true,
  },
  {
    field: "email",
    headerName: t("customer-participants:tableHeader.email"),
    valueGetter: (_, row) => row?.participantEmail || "-",
    renderCell: DefaultCell,
    flex: 250,
    sortable: true,
  },
  {
    field: "status",
    headerName: t("shared:status.label"),
    valueGetter: (_, row) => row?.participantAppStatus || "-",
    renderCell: (params) => <StatusView statusType={params.row?.participantAppStatus} />,
    flex: 250,
    sortable: true,
  },
]
