import { Fab } from "@mui/material"
import { appThemePrimaryColor, appThemeSecondaryColor } from "../../app/Shared.Theme"

interface FabButtonProps {
  id?: string
  icon: React.ReactNode
  onClick: () => void
  disabled?: boolean
  size: "small" | "medium" | "large"
  color?: string
  background?: string[]
  customSize?: number
  style?: object
}

export const FabButton = (props: FabButtonProps) => {
  const { id, icon, onClick, disabled, size, color, background, customSize, style } = props
  const buttonColor = color ?? "#fff"
  const buttonBackground = background ?? [appThemePrimaryColor, appThemeSecondaryColor]
  const buttonSize = customSize ?? 40
  const buttonSizeStyle = {
    width: `${buttonSize}px`,
    height: `${buttonSize}px`,
    minHeight: `${buttonSize}px`,
    maxHeight: `${buttonSize}px`,
  }
  return (
    <Fab
      id={id}
      size={size}
      disabled={disabled}
      style={style}
      onClick={onClick}
      sx={{
        position: "relative",
        right: "initial",
        top: "initial",
        color: buttonColor,
        background: `linear-gradient(to bottom,${buttonBackground.join(",")})`,
        backgroundSize: "100%",
        cursor: "pointer",
        display: "inline-flex",
        overflow: "hidden",
        userSelect: "none",
        verticalAlign: "middle",
        transition: ".3s ease-out",
        ...buttonSizeStyle,
        boxShadow: "none",
        border: "none",
        zIndex: 1,

        "&:hover": { boxShadow: "none" },
        "&::before": {
          position: "absolute",
          content: "''",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: `linear-gradient(to bottom, ${buttonBackground.join(",")})`,
          zIndex: -1,
          transition: ".3s ease-out",
          opacity: 0,
        },
        "&:hover::before": { opacity: 1 },
      }}
      data-testid="fabButton"
    >
      {icon}
    </Fab>
  )
}
