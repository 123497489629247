import { appThemeGrey100, appThemePrimaryColor, appThemeSecondaryColor } from "../../app/Shared.Theme"
import { GradientButton } from "./GradientButton"

interface TogglableButtonProps<T> {
  onClick: (value: T) => void
  label: string
  activeValue: T
  value: T
  height: string
  labelStyle?: object
  fWeight?: number
  sColor?: string
  eColor?: string
  lColor?: string
}

export function TogglableButton<T>(props: TogglableButtonProps<T>) {
  const { onClick, label, value, activeValue, height, labelStyle, fWeight, sColor, eColor, lColor } = props
  const isActive = activeValue === value
  const transparent = "rgba(255,255,255,0)"
  const toggleButtonLabelStyle = labelStyle ?? {}
  const startColor = sColor ?? appThemePrimaryColor
  const endColor = eColor ?? appThemeSecondaryColor
  const labelColor = lColor ?? appThemeGrey100
  return (
    <GradientButton
      height={height}
      size="small"
      startColor={isActive ? startColor : transparent}
      endColor={isActive ? endColor : transparent}
      labelColor={isActive ? "#fff" : labelColor}
      label={label}
      labelStyle={toggleButtonLabelStyle}
      onClick={() => onClick(value)}
      fWeight={fWeight}
      useNewDesign={isActive}
      data-testid="togglableButton"
    />
  )
}
