import { Box } from "@mui/material"
import { useHistory } from "react-router-dom"
import { PrimaryEditButton } from "./PrimaryEditButton"

interface RedirectButtonPropsInterface {
  redirectUrl: string
}

const RedirectButton = (props: RedirectButtonPropsInterface) => {
  const { redirectUrl } = props
  const history = useHistory()
  return (
    <Box sx={{ display: "flex", justifyContent: "end", pt: 2 }}>
      <PrimaryEditButton onClick={() => history.push(redirectUrl)} data-testid="redirectButton" />
    </Box>
  )
}

export default RedirectButton
