import { EditIcon } from "../Shared.Icon"
import { FabButton } from "./FabButton"

interface PrimaryEditButtonProps {
  id?: string
  onClick: () => void
}

export const PrimaryEditButton = (props: PrimaryEditButtonProps) => {
  const { id, onClick } = props
  return (
    <FabButton
      id={id ?? "primary-edit-button"}
      size="medium"
      icon={<EditIcon />}
      onClick={onClick}
      data-testid="primaryEditButton"
    />
  )
}
