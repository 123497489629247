import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import { MeterReadingsCalcResolution, ProductType } from "../../../../data/generated-sources/openapi"
import DateUtils from "../../../../services/utils/DateUtils"
import { apiHeaders } from "../../../Domain.Calls"
import { dashboardOverviewVewaMapper, dashboardOverviewZevMapper } from "./Dashboard.Mapper"
import { DashboardVewaOverview, DashboardZevOverview } from "./Dashboard.Model"

export const getDashboardOverview = async (
  contractId: string,
  productType: ProductType,
  areaId: string,
): Promise<DashboardZevOverview | DashboardVewaOverview | null | undefined> => {
  DOMAIN_DEPENDENCIES.cookie.createLastSelectedZev(DOMAIN_DEPENDENCIES.config.appName, areaId)
  const { data: contractData } = await DOMAIN_DEPENDENCIES.customerContractApi.customerGetContractLastDataPointById(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

  if (contractData?.latestCalcAvailability === undefined) return null

  const { data: meterReadingsResponse } =
    await DOMAIN_DEPENDENCIES.customerMeterReadingsApi.customerGetMeterReadingsByContractId(
      contractId,
      DateUtils.getDeFormatDate(contractData?.latestCalcAvailability),
      DateUtils.getDeFormatDate(contractData?.latestCalcAvailability),
      MeterReadingsCalcResolution.DAY,
      apiHeaders(DOMAIN_DEPENDENCIES),
    )

  if (productType === ProductType.ZEV) {
    if (!meterReadingsResponse?.zev) return null

    return dashboardOverviewZevMapper(contractData?.latestCalcAvailability, meterReadingsResponse?.zev)
  }

  if (productType === ProductType.VEWA) {
    if (!meterReadingsResponse?.vewa) return null

    return dashboardOverviewVewaMapper(contractData?.latestCalcAvailability, meterReadingsResponse?.vewa)
  }
}
