import { Box } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router-dom"
import {
  ParticipantAppParticipantResponse,
  ParticipantAppStatus,
} from "../../../../../../data/generated-sources/openapi"
import { getZevAppParticipantsForArea } from "../../../../../../domain/portal/manager/settings/Settings.Repository"
import { getUniqueId } from "../../../../../../services/utils/SharedUtils"
import { WarningBanner } from "../../../../../../uikit/banner"
import { DataGridTable } from "../../../../../../uikit/dataGridTable"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { ManagerPortalRoutesProps } from "../../../ManagerPortal.Routes"
import { ZevAppParticipantsListColumns } from "./columns"

export const ZevAppParticipants = withRouter(({ match: { params } }: RouteComponentProps<ManagerPortalRoutesProps>) => {
  const { areaId } = params
  const { t } = useTranslation()

  const { data, isLoading, isError } = useQuery(
    ["getZevAppParticipantsForArea", areaId],
    () => getZevAppParticipantsForArea(areaId),
    {
      enabled: !!areaId,
    },
  )

  const finalData = (data?.elements || []).map((item: ParticipantAppParticipantResponse) => ({
    ...item,
    id: getUniqueId(),
  }))

  const isEmailMissing = finalData?.some(
    (participant: ParticipantAppParticipantResponse) =>
      participant.participantAppStatus === ParticipantAppStatus.EMAIL_MISSING,
  )

  return (
    <Box pt={4} pb={2}>
      {isEmailMissing && <WarningBanner message={t("shared:label.emailMissing")} />}
      <ErrorAlert visible={isError} message={t("error-codes:GENERIC_FATAL_ERROR")} />
      <DataGridTable<ParticipantAppParticipantResponse>
        rows={finalData || []}
        columns={ZevAppParticipantsListColumns(t)}
        loading={isLoading}
        data-testid="zevAppParticipantsTable"
      />
    </Box>
  )
})
