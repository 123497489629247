import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import { AreaCustomerResponse } from "../../../../data/generated-sources/openapi"
import { apiHeaders } from "../../../Domain.Calls"

export const customerGetAllAreas = async (): Promise<AreaCustomerResponse[]> => {
  const { data } = await DOMAIN_DEPENDENCIES.customerAreaApi.customerGetAllAreas(apiHeaders(DOMAIN_DEPENDENCIES))

  return data
}

export const customerGetAreaById = async (areaId: string): Promise<AreaCustomerResponse | undefined> => {
  const areas = await customerGetAllAreas()

  return areas.find((area) => area.id === areaId)
}
