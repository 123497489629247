import { FormControlLabel, FormGroup, Switch, SwitchProps } from "@mui/material"
import { styled } from "@mui/material/styles"

const GreenSwitch = styled(Switch)(() => ({
  "& .MuiSwitch-switchBase": {
    color: "grey",
    "&.Mui-checked": { color: "#6C9C30" },
    "&.Mui-checked + .MuiSwitch-track": {
      border: "1px solid #6C9C30",
    },
  },
}))

interface SwitchToggleViewProps {
  label: string
  onChange: (checked: boolean) => void
  defaultChecked?: boolean
  checked?: boolean
  disabled?: boolean
}

export const SwitchToggleView = (props: SwitchToggleViewProps) => {
  const { label, onChange, defaultChecked, checked, disabled } = props
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <GreenSwitch
            checked={checked}
            defaultChecked={defaultChecked}
            color="secondary"
            onChange={(_, checked) => onChange(checked)}
            disabled={disabled}
          />
        }
        label={label}
        data-testid="greenSwitch"
      />
    </FormGroup>
  )
}

export const SwitchToggleManagerView = ({ label, ...rest }: { label: string } & SwitchProps) => {
  return (
    <FormGroup>
      <FormControlLabel sx={{ margin: 0 }} control={<Switch disableRipple {...rest} />} label={label} />
    </FormGroup>
  )
}
