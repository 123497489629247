import { I18nNamespace } from "./App.i18n"

export const SharedI18n: I18nNamespace = {
  name: "shared",
  de: {
    "status.CREATED": "ERSTELLT",
    "status.DONE": "ABGESCHLOSSEN",
    "status.APPROVED": "FREIGEGEBEN",
    "status.DRAFT": "ENTWURF",
    "status.TERMINATED": "TERMINIERT",
    "status.CANCELLED": "STORNIERT",
    "status.ACTIVE": "AKTIV",
    "status.MODIFIED": "MODIFIZIERT",
    "status.INACTIVE": "INAKTIV",
    "status.DELETED": "GELÖSCHT",
    "status.DATA_AVAILABLE": "DATEN LIEGEN VOR",
    "status.WAITING_FOR_DATA": "WARTE AUF DATEN",
    "status.SUSPENDED": "SUSPENDIERT",
    "status.IN_PROGRESS": "IN BEARBEITUNG",
    "status.RELEASED": "FREIGEGEBEN",
    "status.IN_PROGRESS_REOPENED": "IN BEARBEITUNG (NEU ERÖFFNET)",
    "status.PAID": "BEZAHLT",
    "status.NO_ACCESS": "Kein Zugang",
    "status.INVITED": "Eingeladen",
    "status.EMAIL_MISSING": "E-Mail fehlt",
    "status.ACCESS_GRANTED": "Zugang aktiv",
    "label.emailMissing": "E-Mail Adresse fehlt",
    "billing.status.WAITING_FOR_DATA": "Warte auf Daten",
    "billing.status.DATA_AVAILABLE": "Daten liegen vor",
    "billing.status.SUSPENDED": "Suspendiert",
    "billing.status.IN_PROGRESS": "In Bearbeitung",
    "billing.status.IN_PROGRESS_REOPENED": "In Bearbeitung (neu eröffnet)",
    "billing.status.DONE": "Abgeschlossen",
    "billing.status.PAID": "Bezahlt",
    "billing.status.UNPAID": "Unbezahlt",

    "accounting.status.CREATED": "Erstellt",
    "accounting.status.UNAVAILABLE": "Nicht Verfügbar",
    "accounting.status.ERROR": "Fehler",

    "validation.mandatory": "{{field}} ist ein Pflichtfeld",
    "validation.length": "{{field}} muss mindestens {{length}} Zeichen lang sein",
    "validation.max.length": "{{field}} darf maximal {{length}} Zeichen lang sein",
    "validation.valid.email": "Bitte eine gültige E-Mail eingeben",
    "validation.valid.phoneNumber": "{{field}} muss eine gültige Telefonnummer sein",
    "validation.valid.mobileNumber": "{{field}} muss eine gültige Handynummer sein",
    "validation.valid.iban": "{{field}} muss eine gültige IBAN sein",
    "validation.password":
      "Das Passwort ist nicht sicher. Es muss min. 8 Zeichen lang, eine Zahl," +
      " ein Sonderzeichen sowie Gross- und Kleinbuchstaben enthalten",
    "validation.specialCharacter": "{{field}} darf keine Sonderzeichen enthalten",
    "validation.valid.number": "{{field}} muss eine Zahl sein",
    "validation.valid.positiveNumber": "{{field}} muss eine positive Zahl sein",
    "validation.valid.meteringCode": "Der Metering Code muss 33 Zeichen lang sein.",
    "validation.valid.spaces": "Leerzeichen sind nicht erlaubt",

    "table.labelRowsPerPage": "Zeilen pro Seite:",
    "table.labelDisplayedRows": "{{from}}–{{to}} von {{count}}",
    "dialog.confirm.title": "Sind Sie sich sicher?",
    "dialog.confirm.cta": "Ausführen",
    "dialog.close.cta": "Schließen",
    "dialog.confirm.deactivate": "Deaktivieren",
    "alert.successful": "Erfolgreich",
    "alert.error": "Error - Ein Fehler ist aufgetreten",
    "form.filter.label": "Filtern",
    "form.search.label": "Suchen",
    "form.select.week": "Woche vom",
    "form.startDate": "Anfangsdatum",
    "form.endDate": "Enddatum",
    "form.product": "Produkt",
    "form.action.edit": "Bearbeiten",
    "form.action.save": "Speichern",
    "form.action.clear": "Löschen",
    "form.action.back": "Zurück",
    "form.action.cancel": "Abbrechen",

    "consumption.solar": "Solarstrom",
    "consumption.boughtHigh": "Netzbezug - Hochtarif",
    "consumption.boughtLow": "Netzbezug - Niedertarif",
    "solar.ownConsumption": "Eigenverbrauch",
    "solar.sendToNetwork": "Einspeisung ins Netz",
    "chart.legend.subtitle": "LEGENDE",
    "label.ok": "OK",
    "label.error": "Fehler",
    "label.name": "Name",
    "label.area": "Areal",

    "salutation.MR": "Herr",
    "salutation.MS": "Frau",
    "salutation.MR_AND_MS": "Herr und Frau",
    "salutation.MRS": "Herren",
    "salutation.MSS": "Frauen",
    "salutation.SHARED_FLAT": "Wohngemeinschaft",
    "salutation.COMPANY": "Firma",
    "salutation.UNDEFINED": "nicht definiert",

    "billingType.INITIAL": "Initial",
    "billingType.RECURRING": "Wiederkehrend",
    "billingType.MONTHLY_FEE": "Monatlicher Betrag",
    "billingType.INITIAL_FEE": "Initialer einmaliger Betrag",
    "billingType.INITIAL_FEE_PER_CONSUMPTION_POINT": "Initialer einmaliger Betrag pro Zähler",
    "billingType.MONTHLY_FEE_PER_CONSUMPTION_POINT": "Monatlicher Betrag pro Zähler",
    "billingType.MONTHLY_SPECIFIC_FEE_PER_CONSUMPTION_POINT": "Monatlicher Betrag pro Ausführungs-spezifischem Zähler",

    "powerMeterType.HOUSEHOLD_POWERMETER": "Haushaltszähler",
    "powerMeterType.HOUSEHOLD_POWERMETER_ON_RAILS": "Haushaltszähler Hutschiene",
    "powerMeterType.COMMERCIAL_POWERMETER_SMALL": "Gewerbezähler klein",
    "powerMeterType.COMMERCIAL_POWERMETER_LARGE": "Gewerbezähler gross",

    "button.retry": "Erneut versuchen",
    "button.abort": "Abbrechen",
    "button.reset": "Zurücksetzen",
    "button.downloadCsv": "CSV herunterladen",
    "input.hint.add": "hinzufügen",
    "label.empty.list": "Keine Resultate gefunden",
    "label.new": "Neu",
    "picker.year": "Jahr",
    "picker.month": "Monat",
    "picker.week": "Woche",
    "picker.day": "Tag",
    "picker.picked.week": "Woche vom",
    "picker.chooseDate": "Datum wählen",
    "status.label": "Status",
    comingSoon: "Demnächst",
    "response.noData": "No data",
    contracts: "VERTRÄGE",
    "link.goHere": "Zur Nutzeinheit",
    "message.noConfigurationAvailable": "Keine Konfiguration verfügbar",
    several: "Mehrere",
    "label.period": "Zeitraum",
    "label.noChartData": "Keine Daten für dieses Zeitfenster verfügbar",
    "label.reloadData": "Daten neu laden",
    "reloadData.dialogContent":
      "Beim Betätigen des Buttons werden sämtliche Daten neu geladen und die Konfigurationen zurückgesetzt",
    "label.change.billingPeriod": "Abrechnungszeitraum ändern",
    "label.generally": "Allgemein",
    "label.allDay": "ganztägig",
    "label.comment": "Notiz",
    "label.comments": "Notizen",
    "label.initialBill": "Initiale Rechnung",
  },
  en: {
    "status.CREATED": "CREATED",
    "status.DONE": "DONE",
    "status.APPROVED": "APPROVED",
    "status.DRAFT": "DRAFT",
    "status.TERMINATED": "TERMINATED",
    "status.CANCELLED": "CANCELLED",
    "status.ACTIVE": "ACTIVE",
    "status.MODIFIED": "MODIFIED",
    "status.INACTIVE": "INACTIVE",
    "status.DELETED": "DELETED",
    "status.SUSPENDED": "SUSPENDED",
    "status.DATA_AVAILABLE": "DATA AVAILABLE",
    "status.IN_PROGRESS": "IN PROGRESS",
    "status.IN_PROGRESS_REOPENED": "IN PROGRESS (REOPENED)",
    "status.RELEASED": "RELEASED",
    "status.PAID": "Paid",
    "status.NO_ACCESS": "No access",
    "status.INVITED": "Invited",
    "status.EMAIL_MISSING": "E-mail missing",
    "status.ACCESS_GRANTED": "Access granted",
    "label.emailMissing": "E-mail address missing",
    "billing.status.WAITING_FOR_DATA": "Waiting for data",
    "billing.status.DATA_AVAILABLE": "Data available",
    "status.WAITING_FOR_DATA": "WAITING FOR DATA",
    "billing.status.SUSPENDED": "Suspended",
    "billing.status.IN_PROGRESS": "In progress",
    "billing.status.IN_PROGRESS_REOPENED": "In progress (reopened)",
    "billing.status.DONE": "Completed",
    "billing.status.PAID": "Paid",
    "billing.status.UNPAID": "Unpaid",

    "accounting.status.CREATED": "Created",
    "accounting.status.UNAVAILABLE": "Unavailable",
    "accounting.status.ERROR": "Error",

    "validation.mandatory": "{{field}} is a required field",
    "validation.length": "{{field}} must be at least {{length}} characters long",
    "validation.max.length": "{{field}} can be maximum {{length}} characters long",
    "validation.valid.email": "Please enter a valid E-mail address",
    "validation.valid.phoneNumber": "{{field}} must be a valid phone number",
    "validation.valid.mobileNumber": "{{field}} must be a valid mobile phone number",
    "validation.valid.iban": "{{field}} must be a valid IBAN",
    "validation.specialCharacter": "{{field}} can not contain special characters",
    "validation.password":
      "The password is not secure. It must contain at" +
      " least 8 characters, a number, a special character and upper and lower case letters",
    "validation.valid.number": "{{field}} must be a number",
    "validation.valid.positiveNumber": "{{field}} must be a positive number",
    "validation.valid.meteringCode": "The metering code must be 33 characters long.",
    "validation.valid.spaces": "Spaces are not allowed",

    "table.labelRowsPerPage": "Rows per table",
    "table.labelDisplayedRows": "{{from}}–{{to}} of {{count}}",
    "dialog.confirm.title": "Are you sure?",
    "dialog.confirm.cta": "Run",
    "dialog.close.cta": "Close",
    "dialog.confirm.deactivate": "Deactivate",
    "alert.successful": "Successful",
    "alert.error": "Error",

    "form.filter.label": "Filter",
    "form.search.label": "Search",
    "form.select.week": "Week of",
    "form.startDate": "Start date",
    "form.endDate": "End date",
    "form.product": "Product",
    "form.action.edit": "Edit",
    "form.action.save": "Save",
    "form.action.clear": "Clear",
    "form.action.back": "Back",
    "form.action.cancel": "Cancel",
    "consumption.solar": "Solar power",
    "consumption.boughtHigh": "Grid purchase - high tariff",
    "consumption.boughtLow": "Grid purchase - low tariff",
    "solar.ownConsumption": "Own Consumption",
    "solar.sendToNetwork": "Send to Network",
    "chart.legend.subtitle": "LEGEND",
    "label.ok": "OK",
    "label.error": "Error",
    "label.name": "Name",
    "label.area": "Area",

    "salutation.MR": "Mr",
    "salutation.MS": "Ms",
    "salutation.MR_AND_MS": "Mr and Ms",
    "salutation.MRS": "Mrs",
    "salutation.MSS": "Ms",
    "salutation.SHARED_FLAT": "Shared flat",
    "salutation.COMPANY": "Company",
    "salutation.UNDEFINED": "Undefined",

    "billingType.INITIAL": "Initial",
    "billingType.RECURRING": "Recurring",
    "billingType.MONTHLY_FEE": "Monthly fee",
    "billingType.INITIAL_FEE": "Initial fee",
    "billingType.INITIAL_FEE_PER_CONSUMPTION_POINT": "Initial one-off amount per meter",
    "billingType.MONTHLY_FEE_PER_CONSUMPTION_POINT": "Monthly amount per meter",
    "billingType.MONTHLY_SPECIFIC_FEE_PER_CONSUMPTION_POINT": "Monthly amount per run-specific meter",

    "powerMeterType.HOUSEHOLD_POWERMETER": "Household powermeter",
    "powerMeterType.HOUSEHOLD_POWERMETER_ON_RAILS": "Household powermeter on rails",
    "powerMeterType.COMMERCIAL_POWERMETER_SMALL": "Small commercial powermeter",
    "powerMeterType.COMMERCIAL_POWERMETER_LARGE": "Large commercial powermeter",

    "button.retry": "Retry",
    "button.abort": "Abort",
    "button.reset": "Reset",
    "button.downloadCsv": "Download CSV",
    "input.hint.add": "add",
    "label.empty.list": "no results found",
    "label.new": "New",
    "picker.year": "Year",
    "picker.month": "Month",
    "picker.week": "Week",
    "picker.day": "Day",
    "picker.picked.week": "Week of",
    "picker.chooseDate": "Select date",
    "status.label": "Status",
    comingSoon: "Coming soon",
    "response.noData": "No data",
    contracts: "CONTRACT",
    "link.goHere": "Go Here",
    "message.noConfigurationAvailable": "No configuration available",
    several: "Several",
    "label.period": "Period",
    "label.noChartData": "No available data for selected date",
    "label.reloadData": "Reload data",
    "reloadData.dialogContent": "When you press the button, all data is reloaded and the configurations are reset",
    "label.change.billingPeriod": "Change billing period",
    "label.generally": "Generally",
    "label.allDay": "all day",
    "label.comment": "Comment",
    "label.comments": "Comments",
    "label.initialBill": "Initial bill",
  },
}
