import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import {
  BillingType,
  CostPosition,
  CustomerContractResponse,
  MultiParticipantAppParticipantResponse,
} from "../../../../data/generated-sources/openapi"
import { apiHeaders } from "../../../Domain.Calls"

export const getContractFeesByContractId = async (contractId: string): Promise<CustomerContractResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerGetContractContractDetailsById(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  const collections = data?.costPositions?.sort((a, b) => a.billingType.localeCompare(b.billingType))
  let prevBillData = collections[0].billingType

  data.costPositions = collections?.map((item: CostPosition, index: number) => {
    if (index && prevBillData === item?.billingType) {
      item.billingType = "" as BillingType
    } else {
      prevBillData = item.billingType
    }
    return item
  })

  return data
}

export const getZevAppParticipantsForArea = async (areaId: string): Promise<MultiParticipantAppParticipantResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.customerAreaApi.customerGetParticipantAppParticipantsForArea(
    areaId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}
