import { Button, ButtonTypeMap, CircularProgress } from "@mui/material"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"
import { appThemePrimaryLightColor } from "../../app/Shared.Theme"
import { PrimaryButtonProps } from "./PrimaryButton"

export const PrimaryOutlinedButton = (props: PrimaryButtonProps & DefaultComponentProps<ButtonTypeMap>) => {
  const { endIcon, isLoading, label, ...rest } = props
  const endIconCustom = isLoading ? (
    <CircularProgress style={{ color: appThemePrimaryLightColor }} size={20} />
  ) : (
    endIcon
  )

  return (
    <Button
      variant="outlined"
      endIcon={endIconCustom}
      disabled={isLoading}
      data-testid="primaryOutlinedButton"
      {...rest}
    >
      {label}
    </Button>
  )
}
