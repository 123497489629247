import { Box, Checkbox, IconButton, Stack, Typography } from "@mui/material"
import { GridCellParams, GridRenderCellParams, GridRow, GridRowProps } from "@mui/x-data-grid"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

import {
  AccountingStatus,
  BillDetails,
  PaymentStatus,
  SummaryPosition,
  SummaryType,
} from "../../../../data/generated-sources/openapi"
import { FormatStringAsNumber } from "../../../../domain/Domain.Formatters"
import {
  ProductName,
  ZevAdminAllParticipantBilling,
  ZevAdminIndividualParticipantBilling,
} from "../../../../domain/portal/admin/billings/participant/BillingsParticipant.Model"
import {
  ZevAllParticipantBilling,
  ZevIndividualParticipantBilling,
} from "../../../../domain/portal/manager/billings/Billings.Model"
import { DataGridTable, DefaultCell } from "../../../../uikit/dataGridTable"
import { FileDownloadCard, KeyboardArrowDownIcon, KeyboardArrowUpIcon } from "../../../../uikit/Shared.Icon"
import { isAdminPortal } from "../../../Shared.Utils"
import { ParticipationTypography, zevParticipationColumns } from "./ZevBillingParticipations"
import { ZevUnitConsumptionChart } from "./ZevUnitConsumptionChart"

interface ZevBillingAggregateConsumptionProps {
  isLoading: boolean
  billing?:
    | ZevAllParticipantBilling
    | ZevIndividualParticipantBilling
    | ZevAdminAllParticipantBilling
    | ZevAdminIndividualParticipantBilling
  isFinallizeBilling?: boolean
  onDownloadClick?: (billId: string) => void
  onToggleBillPay?: (billId: string, paidOrUnpaid: "paid" | "unpaid") => void
}

export const ZevBillingAggregateConsumption = ({
  isLoading,
  billing,
  isFinallizeBilling,
  onDownloadClick,
  onToggleBillPay,
}: ZevBillingAggregateConsumptionProps) => {
  const { t } = useTranslation("settlements")
  const [expandedRowId, setExpandedRowId] = useState<string>("")

  const handleExpandRowClick = (rowId: string) => {
    const selectedRowId = expandedRowId === rowId ? "" : rowId
    setExpandedRowId(selectedRowId)
  }

  const handleCellClick = ({ field, row }: GridCellParams) => {
    if (field === "download" || field === "status") return
    handleExpandRowClick(row?.id)
  }

  const ArrowCell = useCallback(
    ({ value }: GridRenderCellParams) => {
      const sameRowId = expandedRowId === value
      const expandId = sameRowId ? "" : value
      return (
        <IconButton aria-label="expand row" size="small" onClick={() => handleExpandRowClick(expandId)}>
          {sameRowId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )
    },
    [expandedRowId],
  )
  const NumberCell = useCallback(({ value }: GridRenderCellParams) => {
    return <ParticipationTypography>{FormatStringAsNumber(value)}</ParticipationTypography>
  }, [])

  const ChfCell = useCallback(({ value }: GridRenderCellParams) => {
    return <ParticipationTypography>{`CHF ${FormatStringAsNumber(value)}`}</ParticipationTypography>
  }, [])

  const DownloadBtn = useCallback(
    ({ value }: GridRenderCellParams) => {
      return (
        <IconButton
          disabled={isLoading}
          sx={{ cursor: "pointer" }}
          onClick={() => onDownloadClick && onDownloadClick(value)}
        >
          <FileDownloadCard />
        </IconButton>
      )
    },
    [isLoading],
  )

  const ToggleBillPay = useCallback(
    ({ value }: GridRenderCellParams) => {
      const { paymentStatus, id } = value
      const payee = paymentStatus === PaymentStatus.PAID ? "unpaid" : "paid"
      return (
        <>
          <Checkbox
            size="small"
            disabled={isLoading}
            checked={paymentStatus === PaymentStatus.PAID}
            sx={{ cursor: "pointer", "&.Mui-checked": { color: "#000" } }}
            onClick={() => onToggleBillPay && onToggleBillPay(id, payee)}
          />
          <Typography fontSize={14}>{t("shared:billing.status.PAID")}</Typography>
        </>
      )
    },
    [isLoading],
  )

  const ExpandableTableRow = useCallback(
    (props: GridRowProps) => {
      const { rowId, row } = props
      const isSelectedRow = rowId === expandedRowId
      const expandedStyle = isAdminPortal()
        ? { "&:nth-of-type(odd)": { backgroundColor: "#fafafb" } }
        : { backgroundColor: "#fafafb" }

      const productName = billing?.productName?.toLowerCase() ?? ""
      const isPremiumContract = [
        ProductName.ZEV_PREMIUM.toLowerCase(),
        ProductName.ZEV_PREMIUM_PLUS.toLowerCase(),
      ].includes(productName)

      const accountingErrorMessage =
        row?.accountingStatus === AccountingStatus.ERROR && row?.accountingErrorMessage
          ? row?.accountingErrorMessage?.split("message=")?.pop()?.replace(/\)\]/g, "")
          : false

      return (
        <>
          <GridRow {...props} selected={isSelectedRow} key={rowId} />
          {isSelectedRow && (
            <Box p={2} pb={4} sx={{ ...expandedStyle }} data-testid="ExpandableTableRow">
              <>
                {isPremiumContract && isAdminPortal() && (
                  <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"flex-end"} py={2} px={4}>
                    {accountingErrorMessage ? (
                      <Typography color={"red"} fontSize={14} fontWeight={400}>
                        {accountingErrorMessage}
                      </Typography>
                    ) : (
                      <>
                        <Typography fontWeight={"bold"} mr={1}>
                          {`${t("billings-participant:sap-billing-id")} :`}
                        </Typography>
                        <Typography component={"span"} mr={3}>
                          {row?.orderReferenceNumber ?? "-"}
                        </Typography>
                        <Typography fontWeight={"bold"} mr={1}>
                          {`${t("billings-participant:sap-invoice-id")} :`}
                        </Typography>
                        <Typography component={"span"}>{row?.invoiceReferenceNumber ?? "-"}</Typography>
                      </>
                    )}
                  </Stack>
                )}
              </>
              <ZevUnitConsumptionChart {...(row as BillDetails)} pricePackages={billing?.pricePackages} />
            </Box>
          )}
        </>
      )
    },
    [expandedRowId],
  )

  const sapStatusColumn: Array<GridBaseColDef> = [
    {
      field: "sapStatus",
      headerName: t("billings-participant:detail.all.consumption.list.label.sapStatus"),
      valueGetter: (_, row) => row.accountingStatus,
      renderCell: ({ value, ...rest }) => <DefaultCell {...rest} value={t(`shared:accounting.status.${value}`)} />,
      flex: 120,
    },
  ]
  const toggleBillPayColumn: Array<GridBaseColDef> = [
    {
      field: "status",
      headerName: t("billings-participant:detail.all.consumption.list.label.status"),
      valueGetter: (_, row) => row,
      renderCell: ToggleBillPay,
      flex: 120,
    },
  ]

  const downloadColumn: Array<GridBaseColDef> = [
    {
      field: "download",
      headerName: "",
      valueGetter: (_, row) => row?.id,
      renderCell: DownloadBtn,
      flex: 50,
    },
  ]

  const extendedColumns: Array<GridBaseColDef> = [
    ...zevParticipationColumns(t),
    {
      field: "energyConsumption",
      headerName: "kWh",
      renderCell: NumberCell,
      valueGetter: (_, row) => {
        return row?.billingCalculations?.summaryPositions.map((summary: SummaryPosition) => {
          if (summary.summaryType === SummaryType.TOTAL_ENERGY_CONSUMPTION) {
            return summary.consumption
          }
        })
      },
      flex: 100,
    },
    {
      field: "price",
      headerName: t("billings-participant:detail.all.consumption.list.label.price"),
      valueGetter: (_, row) => {
        return row?.billingCalculations?.summaryPositions
          ?.filter((summary: SummaryPosition) => summary.summaryType === SummaryType.FINAL_AMOUNT_DUE)
          .map((sum: SummaryPosition) => sum.amountDue)[0]
      },
      renderCell: ChfCell,
      flex: 100,
    },
    ...(isFinallizeBilling && isAdminPortal() ? sapStatusColumn : []),
    ...(isFinallizeBilling && billing?.productName?.toLowerCase()?.includes(ProductName.ZEV_COMFORT.toLowerCase())
      ? [...(!billing?.hasCollection ? toggleBillPayColumn : []), ...downloadColumn]
      : []),
    {
      field: "expand",
      headerName: "",
      valueGetter: (_, row) => row?.id,
      renderCell: ArrowCell,
      flex: 40,
    },
  ]

  return (
    <Box data-testid="ZevBillingAggregateConsumptionPage">
      {isFinallizeBilling ? (
        <Typography mb={4} fontSize={24} fontWeight={400}>
          {t("billingEditDone.title.usage")}
        </Typography>
      ) : (
        <Typography mb={4} fontSize={24} fontWeight={400}>
          {t("aggregateConsumption.title")}
        </Typography>
      )}

      <DataGridTable<BillDetails>
        rows={billing?.bills || []}
        loading={isLoading}
        columns={extendedColumns}
        getRowHeight={() => 64}
        getRowClassName={() => "RowClickable"}
        onCellClick={handleCellClick}
        slots={{ row: ExpandableTableRow }}
        onPaginationModelChange={() => setExpandedRowId("")}
        data-testid="ZevBillingAggregateConsumptionTable"
        sx={{
          ".MuiDataGrid-virtualScroller": {
            ...(expandedRowId && {
              height: { sm: "1550px !important", md: "1450px !important", lg: "1250px !important" },
            }),
          },
        }}
      />
    </Box>
  )
}
